<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('loyalty.moments.send') && companyDetailsLoaded && companyPaymentStatus === true" class="event events settings">
    <CCol cols="12" lg="12">
      <CCard class="mb-0 moment wizard">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="12" xl="12" class="pt-0 pb-0 text-left">
              <span>{{$t('sm.Send_a_spark')}}</span>
            </CCol>         
          </CRow>
        </CCardHeader>
        <CCardBody class="event moment">
          <CRow v-if="momentTemplatesLoading === true || momentTemplatesTagsLoaded === false">
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
            </CCol>
          </CRow>
          <CRow v-if="momentTemplatesLoading === false && momentTemplatesTagsLoaded === true">
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <CRow class="p-0">
                <CCol cols="12" lg="12" class="p-0">
                  <b-steps v-model="activeWizardStep"
                           size="is-small"
                           class="wizard_steps h-100"
                           @input="nextWizardStep()"
                           :animated="isAnimated"
                           :rounded="isRounded"
                           :has-navigation="hasNavigation"
                           :icon-prev="prevIcon"
                           :icon-next="nextIcon"
                           :label-position="labelPosition"
                           :mobile-mode="mobileMode">

                    <b-step-item :label="$t('loyalty.Select_card')" :clickable="isStepsClickable" class="wizard_step_item p-0">
                      <div class="text-center mt-4 mb-2">
                        <h2 class="step_title m-0">{{$t('loyalty.Select_a_card_to_send')}}</h2>
                      </div>                         
                      <div v-if="momentTemplateTags.length > 0">                      
                        <CRow class="w-100 m-0">
                          <CCol cols="12" lg="12" class="pt-0 pb-0">                    
                            <div class="filter_tags" v-bind:class="{'show_more': showAllMomentTemplateTags}">
                              <b-tag v-for="tag in momentTemplateTags" 
                                     v-bind:key="tag.moment_tag"
                                     @click.native="setSelectedMomentTemplateTags(tag.moment_tag)"
                                     class="pointer"
                                     v-bind:class="{'selected' : momentTemplateFilters.moment_tags.includes(tag.moment_tag)}">
                                {{tag.moment_tag}} ({{tag.moments_with_tag}})
                              </b-tag>
                            </div>
                            <div v-if="momentTemplateTags.length > 30" class="mt-2">
                              <span class="pointer" @click="toggleMomentTemplateTags()">{{!showAllMomentTemplateTags ? $t('communications.Show_more_tags') : $t('communications.Show_less_tags')}}</span>
                            </div>
                          </CCol>
                        </CRow>
                      </div>
                      <div v-if="momentTemplates.length > 0">
                        <CRow class="w-100 m-0 moment_catalog">
                          <CCol v-for="momentTemplate in currentPageMomentTemplates[currentMomentTemplatePage - 1]" :key="momentTemplate.loyalty_moment_template_id_external" cols="3" xl="3" lg="3" md="4" class="p-0">
                            <loyaltyMomentTemplateCard :momentData="momentTemplate" 
                                                       :showEditButton="false"
                                                       :showMomentTag="false"
                                                       parent="wizard"
                                                       @click.native="setSelectedTemplate(momentTemplate.loyalty_moment_template_id_external);"
                                                       class="pointer"
                                                       v-bind:class="{'selected' : selectedMomentTemplateIdExternal === momentTemplate.loyalty_moment_template_id_external}">
                            </loyaltyMomentTemplateCard>
                          </CCol>
                        </CRow>
                        <hr v-if="momentTemplates.length > momentTemplatesPerPage" class="m-0">
                        <CRow v-if="momentTemplates.length > momentTemplatesPerPage" class="m-0">
                          <CCol cols="12" md="12">
                            <v-pagination class="justify-content-end" v-model="currentMomentTemplatePage" :length="momentTemplatePages" :total-visible="9" prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right"></v-pagination>
                          </CCol>
                        </CRow>
                      </div>
                      <div v-else>
                        <CRow class="w-100 m-0">
                          <CCol cols="12" lg="12">
                            <span>{{$t('sm.No_sparks_available')}}</span>
                          </CCol>
                        </CRow>
                      </div>
                    </b-step-item>

                    <b-step-item :label="$t('communications.Personalize')" :clickable="isStepsClickable" class="wizard_step_item p-0">
                      <div class="mt-4" :class="{'mb-2' : momentTemplateLoading === false, 'mb-4' : momentTemplateLoading === true}">
                        <CRow class="w-100 m-0">
                          <CCol cols="12" lg="12" class="pt-0 pb-0">
                            <div class="d-flex align-items-center" :class="{'text-center' : !checkPermission('loyalty.moments.addcustomtemplate')}">
                              <h2 class="step_title m-0 flex-grow-1">{{$t('loyalty.Personalize_your_card')}}</h2>
                              <CButton v-if="checkPermission('loyalty.moments.addcustomtemplate') && emailEditorReady" 
                                      color="primary"
                                      class="m-0 btn_small orange"
                                      @click="prepareCustomTemplate()">
                                <i class="fas fa-envelope-circle-check mr-2"/>
                                <span>{{$t('sm.Save_as_custom_spark_template')}}</span>
                              </CButton>
                            </div>
                          </CCol>
                        </CRow>

                      </div>
                      <div v-if="selectedMomentTemplateIdExternal && momentTemplateLoading === true">
                        <CRow>
                          <CCol cols="12" lg="12" class="pt-0 pb-0">
                            <loadingSpinner mode="auto" :content="$t('loyalty.Preparing_your_card')"/>
                          </CCol>
                        </CRow>                        
                      </div>
                      <div v-else>
                        <CRow class="w-100 m-0">
                          <CCol cols="12" lg="12" class="pt-0 pb-0">
                            <label><b>{{$t('loyalty.Subject_of_card')}}</b></label>
                            <CInput type="text" class="mb-0" v-model="moment.subject" required was-validated/>
                          </CCol>
                        </CRow>                      
                        <CRow class="w-100 m-0">
                          <CCol cols="12" lg="12" class="pb-0">
                            <label class="mt-0 mb-2"><b>{{$t('loyalty.Content_of_card')}}</b></label>
                            <div v-if="moment.email_content_tags" class="mb-1">
                              <span>{{ $t('loyalty.Tags_available_for_card') }} {{moment.email_content_tags}}</span>
                            </div>
                          </CCol>
                        </CRow>
                        <CRow class="w-100 m-0">
                          <CCol cols="12" lg="12">
                            <EmailEditor ref="emailEditor_loyalty_moment"
                                         :appearance="emailEditorConfig.appearance"
                                         :min-height="emailEditorConfig.minHeight"
                                         :project-id="emailEditorConfig.projectId"
                                         :locale="emailEditorConfig.locale"
                                         :tools="emailEditorConfig.tools"
                                         :options="emailEditorConfig.options"
                                         v-on:ready="editorReady()">
                            </EmailEditor>
                          </CCol>
                        </CRow>
                      </div>
                    </b-step-item>

                    <b-step-item :label="$t('common.Coins')" :clickable="isStepsClickable" class="wizard_step_item p-0">
                      <CRow class="w-100 m-0">
                        <CCol cols="12" xl="12" lg="12" md="12" sm="12">
                          <div class="d-flex align-items-center justify-content-center flex-column wizard_step_content">
                            <div class="text-center">
                              <h2 class="step_title m-0" v-bind:class="{'large' : !moment.grant_points}">{{$t('sm.Do_you_want_to_grant_coins_for_spark')}}</h2>
                            </div>                          
                            <div class="d-flex align-items-center justify-content-center step_option_cards">
                              <CCard @click="moment.grant_points = false; $forceUpdate();" class="pointer" v-bind:class="{'selected' : moment.grant_points === false}">
                                <CCardBody class="d-flex align-items-center justify-content-center">
                                  <i class="fa-solid fa-circle-xmark mr-2"></i>
                                  <span>{{$t('sm.Dont_grant_coins_for_moment')}}</span>
                                </CCardBody>
                              </CCard>
                              <CCard @click="moment.grant_points = true; $forceUpdate();" class="pointer" v-bind:class="{'selected' : moment.grant_points === true}">
                                <CCardBody class="d-flex align-items-center justify-content-center">
                                  <i class="fa-solid fa-circle-check mr-2"></i>
                                  <span>{{$t('sm.Grant_coins_for_moment')}}</span>
                                </CCardBody>
                              </CCard>
                            </div>
                            <div v-if="moment.grant_points" class="w-100 mt-4 mb-4">
                              <CRow class="w-100 m-0 loyalty moment_points">
                                <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pt-0 pb-0">
                                  <div class="d-flex align-items-center justify-content-center">
                                    <div class="currency mr-2">
                                      <img :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/credits/' + environmentTag + '_credit.png/' + clientToken"/>
                                    </div>
                                    <div>
                                      <span class="count d-block">{{moment.points}}</span>                                    
                                    </div>
                                  </div>
                                  <!-- <div class="mt-2 d-flex align-items-center justify-content-center">
                                    <div class="currency small mr-1">
                                      <i class="fa-solid fa-euro-sign"></i>
                                    </div>
                                    <div>
                                      <span class="count small d-block">{{formatToCurrency(moment.points)}}</span>                                  
                                    </div>
                                  </div> -->
                                </CCol>                               
                              </CRow>
                              <CRow class="w-100 m-0 mt-4 mb-4">
                                <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pt-0 pb-0">                                
                                  <b-slider size="is-medium" v-model="moment.points" :min="0" :max="500" :step="10" rounded>
                                    <div class="b-slider-tick null">
                                      <span class="b-slider-tick-label">0</span>
                                    </div>
                                    <template v-for="val in 500">                                      
                                      <b-slider-tick v-if="(val % 50 === 0)" :value="val" :key="val">{{ val ? val : 0 }}</b-slider-tick>                                   
                                    </template>
                                  </b-slider>
                                </CCol>        
                              </CRow>
                              <CRow class="w-100 m-0">
                                <CCol v-for="(value, name, key) in [50, 100, 250, 500]" :key="key" cols="3" xl="3" lg="3" md="6" sm="12" class="pb-0">
                                  <CButton class="w-100" color="primary" @click="moment.points = value;">
                                    <div class="d-flex align-items-center justify-content-center">
                                      <div class="currency mr-1">
                                        <img :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/credits/' + environmentTag + '_credit.png/' + clientToken"/>
                                      </div>
                                      <div>
                                        <span class="d-block">{{value}}</span>                                    
                                      </div>
                                    </div>
                                  </CButton>
                                </CCol>
                              </CRow>
                            </div>
                          </div>
                        </CCol>
                      </CRow>
                    </b-step-item>

                    <b-step-item :label="$t('common.Schedule')" :clickable="isStepsClickable" class="wizard_step_item p-0">
                      <CRow class="w-100 m-0">
                        <CCol cols="12" xl="12" lg="12" md="12" sm="12">
                          <div class="d-flex align-items-center justify-content-center flex-column wizard_step_content">
                            <div class="text-center">
                              <h2 class="step_title m-0" v-bind:class="{'large' : !moment.schedule_moment}">{{$t('sm.Do_you_want_to_schedule_spark')}}</h2>
                            </div>                          
                            <div class="d-flex align-items-center justify-content-center step_option_cards">
                              <CCard @click="setMomentScheduled(false);" class="pointer" v-bind:class="{'selected' : moment.schedule_moment === false}">
                                <CCardBody class="d-flex align-items-center justify-content-center">
                                  <i class="fa-solid fa-bolt mr-2"></i>
                                  <span>{{$t('loyalty.Dont_schedule_this_moment')}}</span>
                                </CCardBody>
                              </CCard>
                              <CCard @click="setMomentScheduled(true);" class="pointer" v-bind:class="{'selected' : moment.schedule_moment === true}">
                                <CCardBody class="d-flex align-items-center justify-content-center">
                                  <i class="fa-solid fa-clock mr-2"></i>                              
                                  <span>{{$t('sm.Schedule_this_spark')}}</span>
                                </CCardBody>
                              </CCard>
                            </div>
                            <div v-if="moment.schedule_moment" class="w-100 mt-4 mb-4">
                              <CRow class="w-100 m-0">
                                <CCol cols="12" xl="12" class="p-0 text-center">
                                  <label><b>{{$t('sm.Schedule_spark_for')}}</b></label>
                                  <b-datetimepicker v-model="moment.scheduled_for" :placeholder="$t('common.click_to_select')" inline editable :datepicker="datepickerOptions" :timepicker="timepickerOptions"></b-datetimepicker>
                                </CCol>
                              </CRow>
                            </div>
                          </div>
                        </CCol>
                      </CRow>
                    </b-step-item>

                    <b-step-item :label="$t('common.Recipients')" :clickable="isStepsClickable" class="wizard_step_item p-0">
                      <CRow class="m-0">
                        <CCol cols="12" lg="12">
                          <div class="d-flex align-items-center justify-content-center flex-column wizard_step_content">            
                            <h2 class="step_title m-0" v-bind:class="{'large' : momentRecipientsType === null}">{{$t('sm.How_to_set_your_recipients')}}</h2>
                            <div class="d-flex align-items-center justify-content-center step_option_cards">
                              <CCard @click="switchMomentRecipientsView('colleagues');" class="pointer" v-bind:class="{'selected' : momentRecipientsType === 'colleagues'}">
                                <CCardBody class="d-flex align-items-center justify-content-center">
                                  <i class="fa-solid fa-people-group mr-2"></i>
                                  <span>{{$t('common.My_team')}}</span>
                                </CCardBody>
                              </CCard>
                              <CCard @click="switchMomentRecipientsView('smart_filters');" class="pointer" v-bind:class="{'selected' : momentRecipientsType === 'smart_filters'}">
                                <CCardBody class="d-flex align-items-center justify-content-center">
                                  <i class="fa-solid fa-users-gear mr-2"></i>
                                  <span>{{$t('core.Smart_filters')}}</span>
                                </CCardBody>
                              </CCard>                              
                              <CCard @click="switchMomentRecipientsView('csv_upload');" class="pointer" v-bind:class="{'selected' : momentRecipientsType === 'csv_upload'}">
                                <CCardBody class="d-flex align-items-center justify-content-center">
                                  <i class="fa-solid fa-file-csv mr-2"></i>
                                  <span>{{$t('common.CSV_upload')}}</span>
                                </CCardBody>
                              </CCard>
                            </div>
   
                            <div v-if="momentRecipientsType === 'colleagues'" class="w-100 mt-4 mb-4">
                              <label class="m-0"><b>{{$t('loyalty.Select_the_recipients_from_your_colleagues')}}</b></label>
                              <div v-if="userColleaguesLoading">
                                <CRow>
                                  <CCol cols="12" lg="12">
                                    <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
                                  </CCol>
                                </CRow>
                              </div>
                              <div v-else>
                                <CRow class="m-0 mt-2">
                                  <CCol cols="12" lg="12" class="p-0 search">
                                    <CInput type="text" class="mb-0 searchbar" v-model="userColleaguesFilters.colleague_name" v-debounce:1s="getUserColleagues"></CInput>
                                  </CCol>
                                </CRow>                                
                                <CRow class="tile-container colleagues_container">
                                  <CCol cols="2" xl="2" lg="3" md="3" sm="12" v-for="colleague in userColleagues" v-bind:key="colleague.user_id_external" class="p-0">
                                    <div class="position-relative colleague">
                                      <i v-if="selectedUserColleagues.includes(colleague.user_id_external)" class="fa-solid fa-check"></i>
                                      <userProfileCard :userData="colleague" @click.native="setSelectedUserColleagues(colleague)" class="pointer"/>
                                    </div>
                                  </CCol>
                                </CRow>
                                <CRow v-if="userColleaguesPaginated">
                                  <CCol cols="12" md="12" class="pt-0 pb-0">                  
                                    <v-pagination class="justify-content-end"
                                                  v-model="userColleaguesCurrentPage"
                                                  @input="onUserColleaguesPageChange"
                                                  :length="userColleaguesPages"
                                                  :total-visible="12"
                                                  prev-icon="mdi-chevron-left"
                                                  next-icon="mdi-chevron-right">
                                    </v-pagination>
                                  </CCol>
                                </CRow>
                              </div>
                            </div>   
                            <div v-if="momentRecipientsType === 'smart_filters'" class="w-100 mt-4 mb-4">
                              <label class="mb-2_5"><b>{{ $t('communications.Specify_recipients') }}</b></label>
                              <CRow v-for="(filter, index) in moment.filters" v-bind:key="index">
                                <CCol cols="12" lg="12" class="pt-0 pb-0">
                                  <div class="mb-2_5 d-flex filters">
                                    <div class="filter_type">
                                      <multiselect class="data_table open_absolute"
                                                   v-model="filter.type" 
                                                   :options="filter.priority === 1 ? groupFilterTypesPrio1 : groupFilterTypesPrio2" 
                                                   :multiple="false"                
                                                   :placeholder="$t('common.Select_filter_type')" 
                                                   :selectLabel="$t('common.Add_filter_type')" 
                                                   :selectedLabel="$t('Selected')"
                                                   :deselectLabel="$t('common.Remove_filter_type')"
                                                   track-by="type_tag" 
                                                   label="type_name"
                                                   :custom-label="customFilterTypeLabel"
                                                   :disabled="filter.type !== null && filter.type !== undefined"
                                                   @input="setupTypeFilter(filter.type.type_tag, index);">
                                        <span slot="noResult">{{ $t('common.no_types_found') }}</span>
                                      </multiselect>
                                    </div>

                                    <div v-if="filter.type" class="filter_condition ml-2" v-bind:class="{'mr-2' : filter.condition}">
                                      <multiselect class="data_table open_absolute"
                                                  v-model="filter.condition" 
                                                  :options="typeFilterConditions" 
                                                  :multiple="false"                
                                                  :placeholder="$t('common.Select_filter_condition')" 
                                                  :selectLabel="$t('common.Add_filter_condition')" 
                                                  :selectedLabel="$t('Added')"
                                                  :deselectLabel="$t('common.Remove_filter_condition')"
                                                  track-by="condition_tag" 
                                                  label="condition_name"
                                                  :custom-label="customFilterConditionLabel"
                                                  :disabled="filter.condition !== null && filter.condition !== undefined"
                                                  @input="$forceUpdate();">
                                        <span slot="noResult">{{ $t('common.no_conditions_found') }}</span>
                                        <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                                      </multiselect>
                                    </div>

                                    <div v-if="filter.type && filter.condition" class="filter_value">
                                      <div v-if="filter.type.type_tag == 'department'">
                                        <multiselect class="data_table open_absolute"
                                                     v-model="filter.value" 
                                                     :options="groupFilterDepartments" 
                                                     :multiple="false"                
                                                     :placeholder="$t('common.Select_department')"
                                                     :selectLabel="$t('common.Add_department')" 
                                                     :selectedLabel="$t('Selected')"
                                                     :deselectLabel="$t('common.Remove_department')"
                                                     track-by="department_id" 
                                                     label="department_name"
                                                     @search-change="asyncFindDepartment"
                                                     @input="getMomentRecipients();">
                                          <span slot="noResult">{{ $t('common.no_departments_found') }}</span>
                                          <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                                        </multiselect>
                                      </div>
                                      <div v-if="filter.type.type_tag == 'team'">
                                        <multiselect class="data_table open_absolute"
                                                     v-model="filter.value" 
                                                     :options="groupFilterTeams" 
                                                     :multiple="false"                
                                                     :placeholder="$t('common.Select_team')" 
                                                     :selectLabel="$t('common.Add_team')" 
                                                     :selectedLabel="$t('Selected')"
                                                     :deselectLabel="$t('common.Remove_team')"
                                                     track-by="team_id" 
                                                     label="team_name"
                                                     @search-change="asyncFindTeam"
                                                     @input="getMomentRecipients();">
                                          <span slot="noResult">{{ $t('common.no_teams_found') }}</span>
                                          <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                                        </multiselect>
                                      </div>
                                      <div v-if="filter.type.type_tag == 'user'">
                                        <multiselect class="data_table open_absolute"
                                                     v-model="filter.value" 
                                                     :options="groupFilterRecipients" 
                                                     :multiple="false"                
                                                     :placeholder="$t('common.Set_employee')" 
                                                     :selectLabel="$t('common.Select_employee')" 
                                                     :selectedLabel="$t('Selected')"
                                                     :deselectLabel="$t('common.Deselect_employee')"
                                                     track-by="user_id_external" 
                                                     label="label"                        
                                                     @search-change="asyncFindRecipient"
                                                     @input="getMomentRecipients();">
                                          <span slot="noResult">{{ $t('common.no_users_found') }}</span>
                                          <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                                        </multiselect>  
                                      </div>
                                      <div v-if="filter.type.type_tag == 'target_group'">
                                        <multiselect class="data_table open_absolute"
                                                     v-model="filter.value" 
                                                     :options="groupFilterTargetGroups" 
                                                     :multiple="false"                
                                                     :placeholder="$t('common.Select_group')" 
                                                     :selectLabel="$t('common.Add_group')" 
                                                     :selectedLabel="$t('Selected')"
                                                     :deselectLabel="$t('common.Remove_group')"
                                                     track-by="group_id" 
                                                     label="title"                      
                                                     @search-change="asyncFindTargetGroup"
                                                     @input="getMomentRecipients();">
                                          <span slot="noResult">{{ $t('common.no_groups_found') }}</span>
                                          <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                                        </multiselect>  
                                      </div>                  
                                      <div v-if="filter.type.type_tag == 'date_started' || filter.type.type_tag == 'date_left'">
                                        <multiselect class="data_table open_absolute"
                                                     v-model="filter.value" 
                                                     :options="groupFilterValues" 
                                                     :multiple="false"                
                                                     :placeholder="$t('common.Select_filter_value')" 
                                                     :selectLabel="$t('common.Add_filter_value')" 
                                                     :selectedLabel="$t('Added')"
                                                     :deselectLabel="$t('common.Remove_filter_value')"
                                                     track-by="type_value_tag" 
                                                     label="type_value_name"
                                                     :custom-label="customFilterTypeValueLabel"
                                                     @input="getMomentRecipients();">
                                          <span slot="noResult">{{ $t('common.no_values_found') }}</span>
                                        </multiselect>   
                                      </div>
                                      <div v-if="userAttributes.includes(filter.type.type_tag)">
                                        <CInput v-debounce:1s="getMomentRecipients"
                                                type="text"
                                                class="attribute mb-0"
                                                v-model="filter.value">
                                        </CInput>
                                      </div>
                                    </div>

                                    <CButton class="ml-2 mr-0 d-inline-block" color="primary" @click="removeFilterOption(index);">
                                      <i class="fas fa-times"/>
                                    </CButton>
                                  </div>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol cols="12" lg="12" class="pt-0">
                                  <CButton color="primary" @click="addFilterOption(1);">
                                    <i class="fas fa-plus mr-1"/>
                                    <span>{{$t('common.Add_required_filter')}}</span>
                                  </CButton>
                                  <CButton color="primary" @click="addFilterOption(2);">
                                    <i class="fas fa-plus mr-1"/>
                                    <span>{{$t('common.Add_additional_filter')}}</span>
                                  </CButton>
                                </CCol>
                              </CRow>
                              <hr class="m-0">
                              <CRow class="group_members">
                                <CCol cols="6" lg="6" class="pb-0 text-left" v-bind:class="{'pb-0' : !listViewEnabled}">
                                  <span><b>{{$t("common.Recipients") }} <span v-if="moment.recipients.length > 0">({{moment.recipients.length}})</span></b></span>
                                  <span class="d-block mt-1 meta">{{$t("core.Only_employees_from_your_team_will_appear_here") }}</span>                                  
                                </CCol>
                                <CCol cols="6" lg="6" class="pb-0 text-right" v-bind:class="{'pb-0' : !listViewEnabled}">
                                  <b-switch v-if="moment.recipients.length > 0" class="mb-0" v-model="listViewEnabled" size="is-small">{{ $t('common.activate_list_view') }}</b-switch>
                                </CCol>
                              </CRow>          
                              <CRow>
                                <CCol cols="12" lg="12" v-bind:class="{'pt-0 pb-0' : !listViewEnabled}">
                                  <div v-if="listViewEnabled">
                                    <CRow>
                                      <CCol cols="12" sm="12" md="12" lg="12" class="pt-0" v-bind:class="{ 'pb-0' : listViewEnabled === true }">
                                        <b-table ref="groupTable" class="data_table" :data="moment.recipients" :striped="true" :hoverable="true" :mobile-cards="true" :paginated="isPaginated" :per-page="perPage" :current-page.sync="currentPage" :pagination-simple="isPaginationSimple" :pagination-position="paginationPosition">
                                          <template slot-scope="props">
                                            <b-table-column field="name" :label="$t('common.Name')">
                                              <div @click="openSidebarRight('user_profile', { user_id_external:  props.row.user_id_external });" class="pointer">
                                                <div v-if="props.row.profile_image" class="profile_image d-inline-block align-middle mr-2" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + props.row.user_id_external + '/' + props.row.profile_image + '/' + clientToken + ')' }"></div>
                                                <div v-else class="profile_icon d-inline-flex align-middle mr-2">
                                                  <userProfileAvatar :username="props.row.name" :size="25"/>
                                                </div>
                                                <b>{{props.row.name}}</b>
                                              </div>                          
                                            </b-table-column>
                                            <b-table-column field="department_name" :label="$t('common.Department')">
                                              <span>{{props.row.department_name ? props.row.department_name : '-'}}</span>
                                            </b-table-column>                        
                                            <b-table-column field="team_name" :label="$t('common.Team')">
                                              <span>{{props.row.team_name ? props.row.team_name : '-'}}</span>
                                            </b-table-column>
                                          </template>
                                          <template slot="empty">
                                            <div class="text-center">
                                              <div v-if="moment.filters.length > 0">
                                                {{ $t('groups.No_users_based_on_filters') }}
                                              </div>
                                              <div v-else>
                                                {{ $t('groups.Add_filters_to_show_members') }}
                                              </div>
                                            </div>                
                                          </template>                
                                        </b-table>                    
                                      </CCol>
                                    </CRow>
                                  </div>
                                  <div v-else>
                                    <div v-if="moment.recipients.length > 0">
                                      <CRow class="group_member_container">
                                        <CCol cols="2" xl="2" lg="3" md="3" sm="12" v-for="employee in currentPageEmployees[employeesCurrentPage - 1]" v-bind:key="employee.user_id_external" class="p-0">
                                          <userProfileCard :userData="employee" @click.native="openSidebarRight('user_profile', { user_id_external:  employee.user_id_external });" class="member pointer"/>
                                        </CCol>
                                      </CRow>
                                      <CRow v-if="moment.recipients.length > employeesPerPage">
                                        <CCol cols="12" md="12" class="pt-0">
                                          <v-pagination class="justify-content-end" v-model="employeesCurrentPage" :length="employeePages" :total-visible="9" prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right"></v-pagination>
                                        </CCol>
                                      </CRow>
                                    </div>
                                    <div v-else>
                                      <CRow>
                                        <CCol cols="12" lg="12">
                                          <span>{{moment.filters.length > 0 ? $t('common.No_employees_based_on_filters') : $t('communications.Add_filters_to_show_recipients')}}</span>
                                        </CCol>
                                      </CRow>
                                    </div>
                                  </div>
                                </CCol>
                              </CRow>
                            </div>                            
                            <div v-if="momentRecipientsType === 'csv_upload'" class="w-100 mt-4 mb-4">
                              <div>
                                <label class="m-0"><b>{{ $t('loyalty.Upload_csv_file_with_recipients') }}</b></label>
                                <CRow>
                                  <CCol cols="12" lg="12">
                                    <div class="d-flex">
                                      <div class="mr-1_5">
                                        <input id="csvUpload" type="file" accept="text/csv" :value="recipientsCSV" @change="onRecipientsCSVChange" hidden>
                                        <CButton @click="pickRecipientsCSV()" color="primary" class="m-0">
                                          <span><i class="fa-solid fa-file-csv mr-1"/>{{$t('common.Upload_CSV_file')}}</span>
                                        </CButton>
                                      </div>
                                      <div>
                                        <CButton @click="downloadExampleRecipientsCSV()" color="primary orange" class="m-0">
                                          <span><i class="fa-solid fa-download mr-1"/>{{$t('common.Download_example_CSV_file')}}</span>
                                        </CButton>
                                      </div>
                                    </div>
                                  </CCol>
                                </CRow>
                              </div>                              
                              <div v-if="csvEmployeesLoading">
                                <CRow>
                                  <CCol cols="12" lg="12">
                                    <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
                                  </CCol>
                                </CRow>
                              </div>
                              <div v-if="recipientsCSVUploaded && !csvEmployeesLoading">
                                <CRow>
                                  <CCol cols="6" lg="6" class="pt-0 pb-0 text-left" v-bind:class="{'pb-0' : !listViewEnabled}">
                                    <span><b>{{$t("common.Recipients") }} <span v-if="moment.recipients.length > 0">({{moment.recipients.length}})</span></b></span>
                                  </CCol>
                                  <CCol cols="6" lg="6" class="pt-0 pb-0 text-right" v-bind:class="{'pb-0' : !listViewEnabled}">
                                    <b-switch v-if="moment.recipients.length > 0" class="mb-0" v-model="listViewEnabled" size="is-small">{{ $t('common.activate_list_view') }}</b-switch>
                                  </CCol>
                                </CRow>
                                <CRow>
                                  <CCol cols="12" lg="12" class="pb-0" v-bind:class="{'pt-0' : !listViewEnabled}">
                                    <div v-if="listViewEnabled">
                                      <CRow>
                                        <CCol cols="12" sm="12" md="12" lg="12" class="pt-0" v-bind:class="{ 'pb-0' : listViewEnabled === true }">
                                          <b-table ref="groupTable" class="data_table" :data="moment.recipients" :striped="true" :hoverable="true" :mobile-cards="true" :paginated="isPaginated" :per-page="perPage" :current-page.sync="currentPage" :pagination-simple="isPaginationSimple" :pagination-position="paginationPosition">
                                            <template slot-scope="props">
                                              <b-table-column field="name" :label="$t('common.Name')">
                                                <div @click="openSidebarRight('user_profile', { user_id_external:  props.row.user_id_external });" class="pointer">
                                                  <div v-if="props.row.profile_image" class="profile_image d-inline-block align-middle mr-2" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + props.row.user_id_external + '/' + props.row.profile_image + '/' + clientToken + ')' }"></div>
                                                  <div v-else class="profile_icon d-inline-flex align-middle mr-2">
                                                    <userProfileAvatar :username="props.row.name" :size="25"/>
                                                  </div>
                                                  <b>{{props.row.name}}</b>
                                                </div>                          
                                              </b-table-column>
                                              <b-table-column field="department_name" :label="$t('common.Department')">
                                                <span>{{props.row.department_name ? props.row.department_name : '-'}}</span>
                                              </b-table-column>                        
                                              <b-table-column field="team_name" :label="$t('common.Team')">
                                                <span>{{props.row.team_name ? props.row.team_name : '-'}}</span>
                                              </b-table-column>
                                            </template>
                                            <template slot="empty">
                                              <div class="text-center">
                                                <span>{{$t('common.No_employees_based_on_CSV_file')}}</span>                                              
                                              </div>                
                                            </template>                
                                          </b-table>                    
                                        </CCol>
                                      </CRow>
                                    </div>
                                    <div v-else>
                                      <div v-if="moment.recipients.length > 0">
                                        <CRow class="group_member_container">
                                          <CCol cols="2" xl="2" lg="3" md="3" sm="12" v-for="employee in currentPageEmployees[employeesCurrentPage - 1]" v-bind:key="employee.user_id_external" class="p-0">
                                            <userProfileCard :userData="employee" @click.native="openSidebarRight('user_profile', { user_id_external:  employee.user_id_external });" class="member pointer"/>
                                          </CCol>
                                        </CRow>
                                        <CRow v-if="moment.recipients.length > employeesPerPage">
                                          <CCol cols="12" md="12" class="pt-0 pb-0">
                                            <v-pagination class="justify-content-end" v-model="employeesCurrentPage" :length="employeePages" :total-visible="9" prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right"></v-pagination>
                                          </CCol>
                                        </CRow>
                                      </div>
                                      <div v-else>
                                        <CRow>
                                          <CCol cols="12" md="12" class="pt-0 pb-0">
                                            <span>{{$t('common.No_employees_based_on_CSV_file')}}</span>
                                          </CCol>
                                        </CRow>
                                      </div>
                                    </div>
                                  </CCol>
                                </CRow>                                                                                        
                              </div>
                            </div>
                          </div>
                        </CCol>
                      </CRow>                      
                    </b-step-item>
                    
                    <b-step-item :label="$t('common.Send')" :clickable="isStepsClickable" class="wizard_step_item p-0">
                      <CRow class="w-100 m-0 mt-4 mb-4 moment_preview">
                        <CCol cols="6" lg="6" md="12" sm="12">
                          <CRow class="w-100 m-0">
                            <CCol cols="12" lg="12" class="p-0">
                              <appPreview :ref="'appPreview'" :environmentName="companyData.environment_name" :environmentTag="environmentTag" :previewData="companyData" previewMode="loyalty_moment" :previewContentData="momentEmailPreview"/>
                            </CCol>
                          </CRow>
                        </CCol>
                        <CCol cols="6" lg="6" md="12" sm="12" class="pt-0 pb-0">
                          <b-tabs class="no_borders mb-0" v-bind:class="{'no_padding' : activeMomentPreviewTab == 0}" type="is-boxed" :animated="false" v-model="activeMomentPreviewTab">
                            <b-tab-item>
                              <template slot="header">
                                <span>{{ $t('sm.Spark_details') }}</span>
                              </template>
                              <template>                                
                                <!-- Loyalty Points -->
                                <div v-if="moment.grant_points">
                                  <span><b>{{$t('sm.Coins_that_will_be_granted')}}</b></span>
                                  <div class="d-flex mt-2">
                                    <div class="d-flex align-items-center currency">
                                      <img v-if="momentPointsCurrency === 'points'" :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/credits/' + environmentTag + '_credit.png/' + clientToken"/>
                                      <i v-if="momentPointsCurrency === 'euro'" class="fa-solid fa-euro-sign"></i>
                                      <span class="ml-1 points">{{momentPointsCurrency === 'points' ? moment.points : formatToCurrency(moment.points)}}</span>
                                    </div>
                                    <!-- <div @click="switchMomentPointsCurrency()" class="ml-1 d-flex align-items-center pointer">
                                      <i class="fa-solid fa-repeat"></i>
                                    </div> -->
                                  </div>
                                </div>                            
                                <!-- Schedule Moment -->
                                <div class="mt-3">
                                  <span><b>{{moment.schedule_moment ? $t('sm.Spark_will_be_sent_on') : $t('sm.Spark_will_be_sent')}}</b></span>
                                  <div class="mt-1">
                                    <span v-if="moment.schedule_moment" class="date">{{moment.scheduled_for | moment("dddd DD-MM-YYYY HH:mm")}}</span>
                                    <span v-else class="date">{{$t('common.Directly')}}</span>
                                  </div>
                                </div>
                                <!-- Recipients-->
                                <div class="mt-3">
                                  <span><b>{{$t('common.Recipients')}}</b></span>
                                  <div class="d-flex align-items-center mt-2 moment_recipients">
                                    <div v-for="(recipient, index) in moment.recipients.slice(0, 10)" :key="index" class="recipient">
                                      <div v-if="recipient.profile_image" class="profile_image" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + recipient.user_id_external + '/' + recipient.profile_image + '/' + clientToken + ')' }"></div>
                                      <div v-else class="profile_icon">
                                        <userProfileAvatar :username="recipient.name" :size="40"/>
                                      </div>
                                    </div>                                        
                                    <div v-if="moment.recipients.length > 10" class="ml-1">
                                      <span><b>{{moment.recipients.length - 10}}+</b></span>
                                    </div>
                                    <div @click="activeMomentPreviewTab = 1;" class="ml-1 pointer">
                                      <span>{{$t('common.Details')}}</span>
                                    </div>
                                  </div>                
                                </div>
                                <!-- Communications -->
                                <div class="mt-3">
                                  <span><b>{{$t('sm.Communicate_spark_by_email')}}</b></span>
                                  <div class="mt-1">
                                    <b-switch class="mb-0 mr-2" v-model="moment.send_moment_email" size="is-small">{{moment.send_moment_email ? $t('loyalty.Send_email_to_recipients') : $t('loyalty.Dont_send_email_to_recipients')}}</b-switch>
                                  </div>
                                </div>
                                <div class="mt-3">
                                  <span><b>{{$t('sm.Communicate_spark_by_push')}}</b></span>
                                  <div class="mt-1">
                                    <b-switch class="mb-0 mr-2" v-model="moment.send_moment_push" size="is-small">{{moment.send_moment_push ? $t('loyalty.Send_push_to_recipients') : $t('loyalty.Dont_send_push_to_recipients')}}</b-switch>
                                  </div>
                                </div>                                
                              </template>
                            </b-tab-item>
                            <b-tab-item>
                              <template slot="header">
                                <span>{{$t('common.Recipients')}} ({{moment.recipients.length}})</span>
                              </template>
                              <template>
                                <CRow class="m-0">
                                  <CCol cols="12" lg="12" class="p-0" v-bind:class="{ 'pb-0' : listViewEnabled === false || isPaginated === false }">                      
                                    <div class="d-flex align-items-center" v-bind:class="{ 'mb-2_5' : listViewEnabled === true }">
                                      <b-switch v-if="moment.recipients.length > 0" class="mb-0" v-model="listViewEnabled" size="is-small">{{ $t('common.activate_list_view') }}</b-switch>
                                    </div>
                                    <div v-if="listViewEnabled">
                                      <CRow>
                                        <CCol cols="12" sm="12" md="12" lg="12" class="p-0">
                                          <b-table class="data_table" 
                                                   :data="moment.recipients"
                                                   :striped="true"
                                                   :hoverable="true"
                                                   :mobile-cards="true"
                                                   :paginated="isPaginated"
                                                   :per-page="perPage"
                                                   :current-page.sync="currentPage"
                                                   :pagination-simple="isPaginationSimple"
                                                   :pagination-position="paginationPosition">
                                            
                                            <template slot-scope="props">
                                              <b-table-column field="name" :label="$t('common.Name')">
                                                <div @click="openSidebarRight('user_profile', { user_id_external:  props.row.user_id_external });" class="pointer">
                                                  <div v-if="props.row.profile_image" class="profile_image d-inline-block align-middle mr-2" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + props.row.user_id_external + '/' + props.row.profile_image + '/' + clientToken + ')' }"></div>
                                                  <div v-else class="profile_icon d-inline-flex align-middle mr-2">
                                                    <userProfileAvatar :username="props.row.name" :size="25"/>
                                                  </div>
                                                  <b>{{props.row.name}}</b>
                                                </div>
                                              </b-table-column>
                                              <b-table-column field="department_name" :label="$t('common.Department')">
                                                <span>{{props.row.department_name}}</span>
                                              </b-table-column>                        
                                              <b-table-column field="team_name" :label="$t('common.Team')">
                                                <span>{{props.row.team_name}}</span>
                                              </b-table-column>                        
                                            </template>
                                          </b-table>
                                        </CCol>
                                      </CRow>
                                    </div>
                                    <div v-else>
                                      <CRow class="tile-container">
                                        <CCol cols="4" xl="4" lg="4" md="4" sm="6" v-for="recipient in currentMomentRecipients[currentMomentRecipientsPage - 1]" v-bind:key="recipient.user_id_external" class="p-0">
                                          <userProfileCard :userData="recipient" @click.native="openSidebarRight('user_profile', { user_id_external:  recipient.user_id_external });" class="pointer"/>
                                        </CCol>
                                      </CRow>
                                      <hr v-if="moment.recipients.length > momentRecipientsPerPage" class="m-0">
                                      <CRow v-if="moment.recipients.length > momentRecipientsPerPage">
                                        <CCol cols="12" md="12">
                                          <v-pagination class="justify-content-end" v-model="currentMomentRecipientsPage" :length="momentRecipientsPages" :total-visible="9" prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right"></v-pagination>
                                        </CCol>
                                      </CRow>
                                    </div>
                                  </CCol>
                                </CRow>
                              </template>
                            </b-tab-item>
                          </b-tabs>
                        </CCol>                          
                      </CRow>
                    </b-step-item>

                    <template v-if="customNavigation" slot="navigation" slot-scope="{previous, next}">
                      <div class="step_navigation">
                        <CButton color="secondary" class="previous" :disabled="previous.disabled" @click.prevent="previous.action">
                          <span><i class="fa-solid fa-chevron-left mr-1"/>{{$t('common.Previous')}}</span>
                        </CButton>
                        <CButton v-if="activeWizardStep !== 5"
                                 color="secondary"
                                 class="next m-0"
                                 :disabled="next.disabled ||
                                            (activeWizardStep === 0 && !selectedMomentTemplateIdExternal) ||
                                            (activeWizardStep === 1 && emailEditorReady === false) ||
                                            (activeWizardStep === 1 && !moment.subject) ||
                                            (activeWizardStep === 2 && moment.grant_points === null) ||
                                            (activeWizardStep === 2 && moment.grant_points === true && moment.points === 0) ||
                                            (activeWizardStep === 3 && moment.schedule_moment === null) ||
                                            (activeWizardStep === 3 && moment.schedule_moment === true && !moment.scheduled_for) ||                                                                                        
                                            (activeWizardStep === 4 && moment.recipients.length == 0)"
                                 @click.prevent="next.action">
                          <span>{{$t('common.Next')}}<i class="fa-solid fa-chevron-right ml-1"/></span>
                        </CButton>
                        <CButton v-if="activeWizardStep === 5" color="primary" class="next m-0" @click="sendMoment();" :disabled="sendButtonDisabled">
                          <div v-if="!sendingMoment">
                            <span><i class="fa-solid mr-1" v-bind:class="{'fa-calendar' : moment.schedule_moment, 'fa-paper-plane' : !moment.schedule_moment}"/>{{moment.schedule_moment ? $t('sm.Schedule_spark') : $t('sm.Send_spark')}}</span>                          
                          </div>
                          <div v-else class="d-flex align-items-center">
                            <loadingSpinner mode="inline"/>
                            <span class="ml-1">{{moment.schedule_moment ? $t('sm.Scheduling_spark') : $t('sm.Sending_spark')}}</span>
                          </div>
                        </CButton>
                      </div>
                    </template>   
                  </b-steps>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>

      <b-modal :can-cancel="['x']" :active.sync="momentRecipientsTypeModal" :width="960" scroll="keep">
        <CCard class="mb-0">
          <CCardHeader class="pb-0">
            {{$t('loyalty.Confirm_switching_recipients_view')}}
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol cols="12" lg="12" class="pt-0 pb-0">
                <span>{{$t('loyalty.You_are_switching_recipients_view')}}</span>
              </CCol>
            </CRow>    
          </CCardBody>
          <CCardFooter>
            <CButton color="primary" @click="setMomentRecipientsView(momentRecipientsTypeSelected); momentRecipientsTypeModal = false;"><i class="fas fa-check mr-1"/>{{$t('Confirm')}}</CButton>
            <CButton color="secondary" @click="momentRecipientsTypeModal = false"><i class="fas fa-times mr-1"/>{{$t('cancel')}}</CButton>
          </CCardFooter>
        </CCard>
      </b-modal>
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded && companyDetailsLoaded" :trigger="!checkPermission('loyalty.moments.send') ? 'permission' : (companyPaymentStatus === false ? 'payment_status' : null)"/>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect';
import { EmailEditor } from 'vue-email-editor';

import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';
import userProfileCard from "@/components/common/userProfileCard.vue";
import userProfileAvatar from "@/components/common/userProfileAvatar.vue";
import appPreview from '@/components/common/appPreview.vue';

import MomentPoints from '@/components/core/MomentPoints.vue';
import loyaltyMomentTemplateCard from '@/components/loyalty/loyaltyMomentTemplateCard.vue';

export default {
  name: 'Moment',
  components: {
    Multiselect,
    EmailEditor,
    loadingSpinner,
    noPermission,
    userProfileCard,
    userProfileAvatar,
    appPreview,
    MomentPoints,
    loyaltyMomentTemplateCard
  }, 
  data() {
    return {
      // Common
      apiBaseUrl: null,
      cdnBaseUrl: null,
      clientToken: null,
      environmentTag: null,
      companyIdExternal: null,
      platformPermissions: [],
      platformPermissionsLoaded: false,
      // Company
      companyData: {},
      companyPaymentStatus: false,
      companyDetailsLoaded: false,
      // Moment Wizard    
      moment: { recipients: [] },
      activeWizardStep: 0,
      isAnimated: false,
      isRounded: true,
      isStepsClickable: false,
      hasNavigation: true,
      customNavigation: true,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',          
      activeMomentPreviewTab: 0,
      currentMomentRecipientsPage: 1,
      currentMomentRecipients: {},
      momentRecipientsPerPage: 12 ,
      momentRecipientsPages: 0,      
      sendButtonDisabled: false,
      sendingMoment: false,
      // Moment Templates
      selectedMomentTemplateIdExternal: null,
      momentTemplates: [],
      momentTemplatesLoading: false,
      momentTemplatesLoaded: false,
      momentTemplateLoading: false,     
      momentTemplateFilters: {
        moment_tags: [],
      },
      currentMomentTemplatePage: 1,
      currentPageMomentTemplates: {},
      momentTemplatesPerPage: 8,
      momentTemplatePages: 0,
      // Moment Template Tags
      momentTemplateTags: [],
      momentTemplatesTagsLoaded: false,
      showAllMomentTemplateTags: false,      
      // Moment Recipients
      momentRecipientsType: null,
      momentRecipientsTypeModal: false,
      momentRecipientsTypeSelected: null,     
      // Moment Recipients - Colleagues
      userColleagues: [],
      selectedUserColleagues: [],      
      userColleaguesLoading: false,
      userColleaguesLoaded: false,
      userColleaguesTotal: 0,
      userColleaguesStartIndex: 0,
      userColleaguesPaginated: false,
      userColleaguesCurrentPage: 1,
      userColleaguesPerPage: 12,
      userColleaguesFilters: {
        collegue_name: ''
      },
      // Moment Recipients - CSV
      recipientsCSV: null,
      recipientsCSVUploaded: false,
      csvEmployeesLoading: false,
      // Moment Recipients - Smart Filters
      groupFilterTypesPrio1: [],
      groupFilterTypesPrio2: [],
      groupFilterConditions: [],
      groupFilterValues: [],
      groupFilterTargetGroups: [], 
      groupFilterRecipients: [],
      groupFilterDepartments: [],
      groupFilterTeams: [],
      typeFilterConditions: [],      
      typeFilterValues: [],
      currentPageEmployees: {},
      employeesCurrentPage: 1,
      employeesPerPage: 12,
      employeePages: 0,       
      userAttributes: ['hours_on_contract', 'meyer_briggs', 'disc', 'office_based', 'division', 'language', 'persona_1', 'persona_2', 'persona_3', 'work_location', 'user_function', 'years_of_service'],
      // Tables
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,      
      listViewEnabled: false,
      // Others
      momentPointsCurrency: 'points',
      momentEmailPreview: null,
      emailEditorReady: false,
      emailEditorConfig: {
        minHeight: '700px',
        locale: 'en',
        projectId: 50935,
        tools: {
          image: { enabled: true } // Disable image tool
        },
        options: {
          mergeTags: {
            name: {
              name: "Employee Name",
              value: "{$name}"
            },
            ceo_name: {
              name: "Email Signatory",
              value: "{$email_signatory}"
            },            
            company: {
              name: "Platform Name",
              value: "{$platform_name}"
            },
            points: {
              name: "Points",
              value: "{$points}"
            },                        
            compliment: {
              name: "Compliment",
              value: "{$compliment}"
            },            
            copyright: {
              name: "Copyright",
              value: "{$copyright}"
            }                          
          },          
          specialLinks: [
            {
              name: 'Frequently used',
              specialLinks: [
                {
                  name: 'Link to Dashboard',
                  href: '{$dashboard_url}',
                  target: '_blank',
                },
                {
                  name: 'Link to Experience',
                  href: '{$app_url}',
                  target: '_blank',
                }
              ]
            }
          ]
        },
        appearance: {
          theme: 'light',
          panels: {
            tools: {
              dock: 'right',
            }
          }
        }              
      },
      datepickerOptions: {
        yearsRange: [0, 10],
        firstDayOfWeek: 1,
        showWeekNumber: true
      },
      timepickerOptions: {
        incrementHours: 1,
        incrementMinutes: 15
      }
    }
  },
  methods: {
    // Moment Wizard
    nextWizardStep() {
      if(this.activeWizardStep === 4) {
        if(!this.momentRecipientsType) this.switchMomentRecipientsView('colleagues');
      } else if(this.activeWizardStep === 5) {
        // Get the email HTML for the preview step
        this.$refs['emailEditor_loyalty_moment'].editor.exportHtml((data) => {
          this.momentEmailPreview = data.html;
          this.momentEmailPreview = this.momentEmailPreview.replaceAll("{$platform_name}", this.companyData.brand_name);
          this.momentEmailPreview = this.momentEmailPreview.replaceAll("{$email_signatory}", this.companyData.email_signatory);
          this.momentEmailPreview = this.momentEmailPreview.replaceAll("{$name}", this.companyData.company_name);
          this.momentEmailPreview = this.momentEmailPreview.replaceAll("{$points}", this.moment.points);
          this.momentEmailPreview = this.momentEmailPreview.replaceAll("{$copyright}", "&#169;" +  new Date().getFullYear());
        })

        this.setMomentRecipientsPagination();
      }
    },
    resetMomentData() {
      this.moment = {
        loyalty_moment_template_id_external: null,        
        recipients: [],
        filters: [],
        subject: null,        
        push_title: null,
        max_push_title_length: 20,
        remaining_push_title_length: 20,
        push_message: null,
        max_push_message_length: 60,
        remaining_push_message_length: 60,
        grant_points: null,
        points: 0,        
        schedule_moment: null,
        scheduled_for: null,
        multiple_recipients: null,
        send_moment_email: true,
        send_moment_push: true
      };      
    },
    setSelectedTemplate(templateIdExternal) {
      // Update the selectedMomentTemplateIdExternal value
      this.selectedMomentTemplateIdExternal = templateIdExternal;
      // Open the next wizard step
      this.activeWizardStep = 1;
      // Get the Moment template details if a new template was selected
      if(this.moment.loyalty_moment_template_id_external !== this.selectedMomentTemplateIdExternal) {
        // Reset the emailEditorReady value
        this.emailEditorReady = false;
        // Get the Moment template details
        this.getMomentTemplateDetails();
      }
    },
    setMomentScheduled(scheduleMoment) {
      this.moment.schedule_moment = scheduleMoment;
      
      if(scheduleMoment === true) {
        // Generate a new date
        let nextNoon = new Date();
        // Set the date to tomorrow
        nextNoon.setDate(nextNoon.getDate() + 1);
        // Set the time to noon
        nextNoon.setHours(12, 0, 0, 0);
        // Update the scheduled_for value
        this.moment.scheduled_for = nextNoon;
      } else {
        this.moment.scheduled_for = null;
      }
      this.$forceUpdate();
    },
    switchMomentPointsCurrency() {
      (this.momentPointsCurrency === 'points') ? this.momentPointsCurrency = 'euro' : this.momentPointsCurrency = 'points';
    },
    setMomentRecipientsPagination() {
      // Set the pagination to the first page
      this.currentMomentRecipientsPage = 1;
      // Reset the momentRecipientsPages value
      this.momentRecipientsPages = 0;
      // Loop througb the headcountDetails to divide the data in pages         
      for (let i = 0; i < this.moment.recipients.length; i = i + this.momentRecipientsPerPage) {
        this.currentMomentRecipients[this.momentRecipientsPages] = this.moment.recipients.slice(i, i + this.momentRecipientsPerPage);
        this.momentRecipientsPages++;
      }
    },    
    sendMoment() {
      // Disable the send button
      this.sendButtonDisabled = true;
      // Show the loader
      this.sendingMoment = true;
      // Set the params
      let params = {};
      params.momentData = this.moment;
      params.momentData.recipients_type = this.momentRecipientsType;
      if(!this.moment.json) params.momentData.json = null;
      if(!this.moment.html) params.momentData.html = null;      
      if(this.moment.points) params.momentData.points = parseInt(this.moment.points);
      
      (params.momentData.send_moment_email == true) ? params.momentData.send_email = 1 : params.momentData.send_email = 0;
      (params.momentData.send_moment_push == true) ? params.momentData.send_push = 1 : params.momentData.send_push = 0;
      
      if(params.momentData.schedule_moment == true) {
        params.momentData.scheduled = 1;
        params.momentData.moment_scheduled_for = this.$luxon(params.momentData.scheduled_for.toISOString(), "yyyy-MM-dd HH:mm:ss");
      } else {
        params.momentData.scheduled = 0;
        params.momentData.moment_scheduled_for = null;
      }
      
      if(params.momentData.grant_points == true) {
        params.momentData.points_granted = 1;
      } else {
        params.momentData.points_granted = 0;
        params.momentData.points = null;
      }

      if(params.momentData.filters) {
        params.momentData.recipient_filters = params.momentData.filters.filter(function (el) {
          return el.type != undefined && el.value != undefined;
        });
      }

      if(this.$refs !== undefined && this.$refs['emailEditor_loyalty_moment'] !== undefined ) {
        this.$refs['emailEditor_loyalty_moment'].editor.saveDesign((design) => {
          params.momentData.json = JSON.stringify(design);
        });

        this.$refs['emailEditor_loyalty_moment'].editor.exportHtml((data) => {
          params.momentData.html = data.html;
        })
      }

      // Set 500ms timeout to make sure that the JSON and HTML data is added to the params
      setTimeout(function() {
        axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/moment', params)
        .then(res => {
          this.$buefy.toast.open({ message: this.moment.schedule_moment ? this.$t('sm.Spark_scheduled') : this.$t('sm.Spark_sent'), type: 'is-success', duration: 2000 });
          // Navigate back to Moments overview
          this.$router.push({path: '/loyalty/moments'});
          // Stop the loader
          this.sendingMoment = false;
          // Enable the send button
          this.sendButtonDisabled = false;          
        })
        .catch(err => {
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
          // Stop the loader
          this.sendingMoment = false;
          // Enable the send button
          this.sendButtonDisabled = false;
        });
      }.bind(this), 500);       
    },
    // Moment Templates
    getMomentTemplates() {
      let params = {};
      params.momentFilters = this.momentTemplateFilters;
      params.include_default_templates = true;
      params.include_custom_design = true;
      params.active_only = true;
      params.randomize_templates = true;
      // Start the loader
      if(this.momentTemplatesLoaded === false) this.momentTemplatesLoading = true;
      // Get the Moment templates
      axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/moment-templates', params)
      .then(res => {
        this.momentTemplates = res.data.data;
        // Set the pagination to the first page
        this.currentMomentTemplatePage = 1;        
        // Reset the momentTemplatePages value
        this.momentTemplatePages = 0;        
        // Loop through the Moment templates to set the pagination
        for (let i = 0; i < this.momentTemplates.length; i = i + this.momentTemplatesPerPage) {
          this.currentPageMomentTemplates[this.momentTemplatePages] = this.momentTemplates.slice(i, i + this.momentTemplatesPerPage);
          this.momentTemplatePages++;
        }
        // Set the available tags
        this.setMomentTemplateTags();
        // Stop the loader
        this.momentTemplatesLoading = false;
        // Update the momentTemplatesLoaded value
        this.momentTemplatesLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      }); 
    },
    getMomentTemplateDetails() {
      // Start the loader
      this.momentTemplateLoading = true;
      // Get the Moment template details      
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/moment-template/' + this.selectedMomentTemplateIdExternal)
      .then(res => {
        let momentTemplateData = res.data.data;        
        // Set the external Moment template ID        
        this.moment.loyalty_moment_template_id_external = this.selectedMomentTemplateIdExternal;       
        // Set the tag
        this.moment.tag = momentTemplateData.tag;
        // Set the name
        this.moment.name = momentTemplateData.name;
        // Set the description
        this.moment.description = momentTemplateData.description;        
        // Set the subject
        this.moment.subject = momentTemplateData.subject;
        // Set the email content tags
        this.moment.email_content_tags = momentTemplateData.email_content_tags;
        // Set the email json
        if(momentTemplateData.json) this.moment.json = momentTemplateData.json;
        // Set the email html
        this.moment.html = momentTemplateData.html;
        // Set the push title
        this.moment.push_title = momentTemplateData.push_title;
        // Set the push message
        this.moment.push_message = momentTemplateData.push_message;
        // Set the Moment image ID
        if(momentTemplateData.loyalty_moment_image_id) this.moment.loyalty_moment_image_id = momentTemplateData.loyalty_moment_image_id;                
        // Set the points
        this.moment.points = momentTemplateData.points;
        // Set the points title
        this.moment.points_title = momentTemplateData.points_title;
        // Set the points description
        this.moment.points_description = momentTemplateData.points_description;        
        // Trigger the countdown method
        this.countdown();
        // Stop the loader
        this.momentTemplateLoading = false;                     
        // Set he mailDesign in the editor if the template contains a JSON
        if(momentTemplateData.json) {
          // Parse the JSON to make sure it is valid
          let mailDesign = JSON.parse(momentTemplateData.json);
          // Wait 2000ms to make sure the email editor is loaded
          setTimeout(function() {
            if(this.$refs['emailEditor_loyalty_moment'] && this.$refs['emailEditor_loyalty_moment'].editor) {
              this.$refs['emailEditor_loyalty_moment'].editor.loadDesign(mailDesign);
            }
          }.bind(this), 3000);
        }
      })
      .catch(err => {
        console.error(err); 
      }); 
    },
    prepareCustomTemplate() {
      let templateData = this.moment;
      if(!this.moment.json) templateData.json = null;
      if(!this.moment.html) templateData.html = null;  

      if(this.$refs !== undefined && this.$refs['emailEditor_loyalty_moment'] !== undefined ) {
        this.$refs['emailEditor_loyalty_moment'].editor.saveDesign((design) => {
          templateData.json = JSON.stringify(design);
        });

        this.$refs['emailEditor_loyalty_moment'].editor.exportHtml((data) => {
          templateData.html = data.html;
        })
      }

      // Set 500ms timeout to make sure that the JSON and HTML data is added to the params
      setTimeout(function() {          
        this.openSidebarRight('loyalty_moment_custom_template_details', { template_data: templateData, product_tag:  'loyalty' });
      }.bind(this), 500);   
    },
    // Moment Template Tags
    setMomentTemplateTags() {
      if(this.momentTemplatesTagsLoaded === false) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/moment-templates/tags')
        .then(res => {
          this.momentTemplateTags = res.data.data;
          // Update the momentTemplatesTagsLoaded value
          this.momentTemplatesTagsLoaded = true;        
        })
        .catch(err => {
          console.error(err); 
        });
      } else {
        // Create array for momentTemplateTags
        let momentTemplateTags = [];
        // Loop through the Moment templates
        for(var g = 0; g < this.momentTemplates.length; g++) {
          momentTemplateTags = momentTemplateTags.concat(this.momentTemplates[g].loyalty_moment_tags);
        }
        // Create momentTemplateTagsCount array with counts of remaining tags
        var momentTemplateTagsCount = momentTemplateTags.reduce((tagCount, tag) => {
          if(tagCount.hasOwnProperty(tag) === false) tagCount[tag] = 0;
          tagCount[tag]++;
          return tagCount;
        }, {});
        
        // Update the momentTemplateTags array
        this.momentTemplateTags = Object.keys(momentTemplateTagsCount).map(tag => {
          return {moment_tag: tag, moments_with_tag: momentTemplateTagsCount[tag]}; 
        });
        // Sort the momentTemplateTags
        this.momentTemplateTags.sort((a,b)=> (b.moments_with_tag - a.moments_with_tag || a.moment_tag.localeCompare(b.moment_tag)));
      }
    },
    setSelectedMomentTemplateTags(tag) {
      // Get the index of the Moment template tag in the moment_tags array of the momentTemplateFilters
      let momentTagIndex = this.momentTemplateFilters.moment_tags.findIndex(x => x === tag);
      // Remove or add the moment tag based on the index
      (momentTagIndex >= 0) ? this.momentTemplateFilters.moment_tags.splice(momentTagIndex, 1) : this.momentTemplateFilters.moment_tags.push(tag);      
      // Get the Moment templates
      this.getMomentTemplates();
    },
    toggleMomentTemplateTags() {
      (this.showAllMomentTemplateTags === false) ? this.showAllMomentTemplateTags = true : this.showAllMomentTemplateTags = false;
    },
    // Moment Recipients
    switchMomentRecipientsView(recipientsView) {
      // Check if a the momentRecipientsType has already been set
      if(this.momentRecipientsType && (this.momentRecipientsType !== recipientsView)) {
        // Update the momentRecipientsTypeSelected value
        this.momentRecipientsTypeSelected = recipientsView;
        // Show the momentRecipientsTypeModal
        this.momentRecipientsTypeModal = true;
       } else if(this.momentRecipientsType === null) {      
        this.setMomentRecipientsView(recipientsView);
       }
    },
    setMomentRecipientsView(recipientsView) {
      this.momentRecipientsType = recipientsView;
      // Clear the recipients array of the moment
      this.moment.recipients = [];
      // Get the collegues when the colleagues view is selected
      if(this.momentRecipientsType === 'colleagues') {
        // Reset the userColleaguesFilters
        this.userColleaguesFilters = { collegue_name: '' };
        // Reset the userColleaguesLoaded value
        this.userColleaguesLoaded = false;
        // Clear the selectedUserColleagues array
        this.selectedUserColleagues = [];
        // Get the user colleagues
        this.getUserColleagues();
      }
      // Clear the filters array of the moment when the smart filters view is selected
      if(this.momentRecipientsType === 'smart_filters') this.moment.filters = [{}];      

      if(this.momentRecipientsType === 'csv_upload') this.recipientsCSVUploaded = false;
      // Force update
      this.$forceUpdate();
    },
    // Moment Recipients - Colleagues
    getUserColleagues() {
      // Start the loader
      if(this.userColleaguesLoaded === false) this.userColleaguesLoading = true;
      // Set the params
      let params = {};
      params.colleague_limit = this.userColleaguesPerPage;
      params.colleague_filters = this.userColleaguesFilters;
      // Get the user colleagues
      axios.post(process.env.VUE_APP_API_URL + '/v1/core/organisation/colleagues/' + this.userColleaguesStartIndex, params)
      .then(res => {
        this.userColleagues = [];
        // Update the userColleaguesTotal value
        this.userColleaguesTotal = res.data.data.total_user_colleagues;
        // Set the default currentTotal value
        let currentTotal = res.data.data.total_user_colleagues;
        // Update the currentTotal value
        if(currentTotal / this.userColleaguesPerPage > 1000) currentTotal = this.userColleaguesPerPage * 1000;
        // Add the user colleagues to the userColleagues array
        this.userColleagues = res.data.data.user_colleagues;        
        // Check if the pagination should be activated
        (this.userColleaguesTotal > this.userColleaguesPerPage) ? this.userColleaguesPaginated = true : this.userColleaguesPaginated = false;
        // Reset the userColleaguesPages value
        this.userColleaguesPages = 0;        
        // Define the number of items of the pagination
        for (let i = 0; i < currentTotal; i = i + this.userColleaguesPerPage) {
          this.userColleaguesPages++;
        }
        // Stop the loader
        this.userColleaguesLoading = false;
        // Update the userColleaguesLoaded value
        this.userColleaguesLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    setSelectedUserColleagues(colleague) {
      // Get the index of the external user ID in the selectedUserColleagues array
      let colleagueIndex = this.selectedUserColleagues.findIndex(x => x === colleague.user_id_external);
      // Remove or add the external user ID based on the index
      (colleagueIndex >= 0) ? this.selectedUserColleagues.splice(colleagueIndex, 1) : this.selectedUserColleagues.push(colleague.user_id_external);
      // Get the index of the recipient in the recipients array of the moment
      let recipientIndex = this.moment.recipients.findIndex(x => x.user_id_external === colleague.user_id_external);      
      // Remove or add the recipient based on the index
      (recipientIndex >= 0) ? this.moment.recipients.splice(recipientIndex, 1) : this.moment.recipients.push(colleague);
    },
    onUserColleaguesPageChange(page) {
      // Set the userColleaguesStartIndex value
      (page > 1) ? this.userColleaguesStartIndex = (page - 1) * this.userColleaguesPerPage : this.userColleaguesStartIndex = 0;
      // Get the user colleagues
      this.getUserColleagues();
    },       
    // Moment Recipients - CSV
    onRecipientsCSVChange(e) {
      // Start the loader
      this.csvEmployeesLoading = true;
      // Get the file        
      let recipientsCSV = e.target.files[0];
      // Create new formData
      const formData = new FormData();
      // Add the file to the formData
      formData.append('file', recipientsCSV);
      // Get the employees based on the CSV
      axios.post(process.env.VUE_APP_API_URL + '/v1/core/organisation/employees/csv', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(res => {
        this.moment.recipients = res.data.data;
        // Enable the pagination if necessary
        (this.moment.recipients.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;         
        // Set the pagination to the first page
        this.employeesCurrentPage = 1;
        // Reset the employeePages value
        this.employeePages = 0;
        // Loop through the Moment to set the pagination            
        for (let i = 0; i < this.moment.recipients.length; i = i + this.employeesPerPage) {
          this.currentPageEmployees[this.employeePages] = this.moment.recipients.slice(i, i + this.employeesPerPage);
          this.employeePages++;
        }
        // Check if the pagination for the table should be enables
        this.moment.recipients.length > this.perPage ? this.isPaginated = true : this.isPaginated = false; 
        // Update the recipientsCSVUploaded value
        this.recipientsCSVUploaded = true;               
        // Stop the loader
        this.csvEmployeesLoading = false;
        // Force update
        this.$forceUpdate();
      })
      .catch(err => {
        console.error(err); 
      });
    },
    pickRecipientsCSV() {
      document.getElementById("csvUpload").click();
    },
    downloadExampleRecipientsCSV() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/employees/csv')
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('common.Export_successful'), type: 'is-success', duration: 2000 });

        if(!window.navigator.msSaveOrOpenBlob){
          // Blob navigator
          let url = null;
          let outputData = null;
          // Set the output data and URL
          outputData = res.data;
          url = window.URL.createObjectURL(new Blob([outputData]));
          // Create a new 'a' element
          const link = document.createElement('a');
          // Set the generated URL as href of the new element
          link.href = url;
          // Set the download attribute
          link.setAttribute('download', this.environmentTag + '_employees.csv');
          // Append the link to the body
          document.body.appendChild(link);
          // Click the link
          link.click();
        } else {
          // Blob for Explorer 11
          let url = null;
          let outputData = null;
          // Set the output data and URL
          outputData = res.data;
          url = window.navigator.msSaveOrOpenBlob(new Blob([outputData]), this.environmentTag + "_employees.csv");
        }
      })
      .catch(err => {
        console.error(err); 
      }); 
    },     
    // Moment Recipients - Smart Filters
    getMomentRecipients() {
      let params = {};
      params.filters = this.moment.filters.filter(function (el) {
        return el.type != undefined && el.value != undefined;
      });
      
      if(params.filters.length > 0) {
        // Get the recipients if there are filters available
        axios.post(process.env.VUE_APP_API_URL + '/v1/core/targetgroup/members', params)
        .then(res => {
          this.moment.recipients = res.data.data;
          // Enable the pagination if necessary
          (this.moment.recipients.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;         
          // Set the pagination to the first page
          this.employeesCurrentPage = 1;
          // Reset the employeePages value
          this.employeePages = 0;
          // Loop through the Moment to set the pagination            
          for (let i = 0; i < this.moment.recipients.length; i = i + this.employeesPerPage) {
            this.currentPageEmployees[this.employeePages] = this.moment.recipients.slice(i, i + this.employeesPerPage);
            this.employeePages++;
          }
          // Check if the pagination for the table should be enables
          this.moment.recipients.length > this.perPage ? this.isPaginated = true : this.isPaginated = false;        
          // Force update
          this.$forceUpdate();
        })
        .catch(err => {
          console.error(err); 
        });
      } else {
        // Reset the recipients array if there are no filters available
        this.moment.recipients = [];
      }
    },
    addFilterOption(priority) {
      // Add new filter to filters array
      this.moment.filters.push({priority: priority});
      // Force update
      this.$forceUpdate();
    },
    removeFilterOption(index) {
      // Remove filter from filters array
      this.moment.filters.splice(index, 1);
      // Update the recipients
      this.getMomentRecipients();
      // Force update
      this.$forceUpdate();
    },
    getGroupFilterTypes() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/filters/types')
      .then(res => {
        let groupFilterTypes = res.data.data;

        this.groupFilterTypesPrio1 = groupFilterTypes.filter(item => item.priority == 1);
        this.groupFilterTypesPrio2 = groupFilterTypes.filter(item => item.priority == 2);
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupFilterTypeValues() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/filters/types/values')
      .then(res => {
        this.groupFilterValues = res.data.data;
        // Set the typeFilterValues array
        this.typeFilterValues = this.groupFilterValues;        
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    getGroupFilterConditions () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/filters/conditions')
      .then(res => {
        this.groupFilterConditions = res.data.data;
        // Set the typeFilterConditions array
        this.typeFilterConditions = this.groupFilterConditions;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getTypeFilterConditions(filterTypeTag, filterIndex) {
      // Reset the typeFilterConditions array
      this.typeFilterConditions = this.groupFilterConditions;
      // Filter the typeFilterConditions based on the filter type tag
      this.typeFilterConditions = this.typeFilterConditions.filter( i => i.available_for_filter_type_tags.includes(filterTypeTag));
      // Auto set the condition if the filter type has only one available
      if(this.typeFilterConditions.length === 1) this.moment.filters[filterIndex].condition = this.typeFilterConditions[0];
    },    
    getTypeFilterValues(filterTypeTag) {
      // Reset the typeFilterValues array
      this.typeFilterValues = this.typeFilterValues;
      // Filter the typeFilterValues based on the filter type tag
      this.typeFilterValues = this.typeFilterValues.filter( i => i.available_for_filter_type_tags.includes(filterTypeTag));
    },
    setupTypeFilter(filterTypeTag, filterIndex) {
      // Get the filter type conditions
      this.getTypeFilterConditions(filterTypeTag, filterIndex);
      // Get the filter values
      if(filterTypeTag === 'department') {
        this.getGroupFilterDepartments();        
      } else if(filterTypeTag === 'team') {
        this.getGroupFilterTeams();        
      } else if(filterTypeTag === 'user') {
        this.getGroupFilterRecipients();        
      } else if(filterTypeTag === 'target_group') {
        this.getGroupFilterTargetGroups();        
      }      
      // Force update
      this.$forceUpdate();
    },
    customFilterTypeLabel({type_tag}) {
      return this.$t('filter.' + type_tag);
    },    
    customFilterConditionLabel({condition_tag}) {
      return this.$t('condition.' + condition_tag);
    },    
    customFilterTypeValueLabel({type_value_tag}) {
      return this.$t('filter_value.' + type_value_tag);
    },
    getGroupFilterTargetGroups() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/multiselect')
      .then(res => {
        this.groupFilterTargetGroups = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupFilterRecipients() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/employees/multiselect')
      .then(res => {
        this.groupFilterRecipients = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupFilterDepartments() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/departments/multiselect')
      .then(res => {
        this.groupFilterDepartments = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupFilterTeams() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/teams/multiselect')
      .then(res => {
        this.groupFilterTeams = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    // Async Search
    asyncFindTargetGroup(query) {
      let searchTerm = query;
      this.groupFilterTargetGroups = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/targetgroup/' + searchTerm)
        .then(res => {      
          this.groupFilterTargetGroups = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },    
    asyncFindRecipient(query) {
      let searchTerm = query;
      this.groupFilterRecipients = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/recipients/' + searchTerm)
        .then(res => {      
          this.groupFilterRecipients = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },      
    asyncFindDepartment(query) {
      let searchTerm = query;
      this.groupFilterDepartments = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/departments/' + searchTerm)
        .then(res => {      
          this.groupFilterDepartments = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindTeam(query) {
      let searchTerm = query;
      this.groupFilterTeams = [];

      if(searchTerm.length >= 2) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/teams/' + searchTerm)
        .then(res => {      
          this.groupFilterTeams = res.data.data;    
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    // Company Details
    getCompanyDetails() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/company')
      .then(res => {      
        let companyData = res.data.data;
        // Set the companyPaymentStatus value
        this.companyPaymentStatus = companyData.payment_status;
        // Check if the company environment has been set
        if(companyData.details.environment_tag) this.companyData.environment_tag = companyData.details.environment_tag;
        // Check if the company environment has been set
        if(companyData.details.environment_name) this.companyData.environment_name = companyData.details.environment_name;               
        // Check if the company name has been set
        if(companyData.details.company_name) this.companyData.company_name = companyData.details.company_name;
        // Check if the brand name has been set
        (companyData.details.brand_name) ? this.companyData.brand_name = companyData.details.brand_name : this.companyData.brand_name = companyData.details.company_name;
        // Check if the email sender name has been set
        if(companyData.details.email_signatory) this.companyData.email_signatory = companyData.details.email_signatory;
        // Check if the company image has been set
        if(companyData.look_and_feel.company_image_id) this.companyData.company_image = this.apiBaseUrl + '/v1/common/cdn/file/image/base-app/' + this.companyIdExternal + '/' + companyData.look_and_feel.company_image_id + '.png' + '/' + this.clientToken
        // Check if the company header image has been set
        if(companyData.look_and_feel.header_image_id) this.companyData.header_image = this.apiBaseUrl + '/v1/common/cdn/file/image/base-app/' + this.companyIdExternal + '/' + companyData.look_and_feel.header_image_id + '.png' + '/' + this.clientToken
        // Check if the app background has been set
        if(companyData.look_and_feel.app_background_image_id) this.companyData.app_background_image = this.apiBaseUrl + '/v1/common/cdn/file/image/base-app/' + this.companyIdExternal + '/' + companyData.look_and_feel.app_background_image_id + '.png' + '/' + this.clientToken;
        // Set the primary color
        (companyData.look_and_feel.primary_color) ? this.companyData.primary_color = companyData.look_and_feel.primary_color : this.companyData.primary_color = companyData.details.environment_primary_color;
        // Set the secondary color
        (companyData.look_and_feel.secondary_color) ? this.companyData.secondary_color = companyData.look_and_feel.secondary_color : this.companyData.secondary_color = companyData.details.environment_secondary_color;        
        // Set the default company image
        this.companyData.default_company_image_url = this.cdnBaseUrl + '/base/dashboard/logo/' + companyData.details.environment_tag + '_logo.png';
        // Set the default social wall image
        this.companyData.default_social_wall_image_url = this.cdnBaseUrl + '/base/dashboard/default-socialwall.jpg';
        // Update the companyDetailsLoaded value
        this.companyDetailsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    // Permissions & Modules
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {      
        this.modules = res.data.data;    
      })
      .catch(err => {
        console.error(err); 
      });
    },
    // Others
    formatToCurrency(points) {
      return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format((points/10));
    },       
    editorReady() {
      this.emailEditorReady = true;
            
      this.$refs['emailEditor_loyalty_moment'].editor.setBodyValues({
        backgroundColor: "#F6F6F6",
        textColor: '#4A4A4A',
        contentAlignment: 'center',
        links: {
          color: '#4A4A4A',
          underline: true
        }
      });
    },    
    countdown() {
      // Calculate remaining push title length
      // if(this.moment.push_title !== null) this.moment.remaining_push_title_length = this.moment.max_push_title_length - this.moment.push_title.length;
      // Calculate remaining push message length
      // if(this.moment.push_messagemessage !== null) this.moment.remaining_push_message_length = this.moment.max_push_message_length - this.moment.push_message.length;
    }
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.cdnBaseUrl = process.env.VUE_APP_CDN_URL;
    this.clientToken = localStorage.getItem('token');
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');
    if(localStorage.getItem('companyIdExternal') !== null) this.companyIdExternal = localStorage.getItem('companyIdExternal');

    this.getCompanyDetails();
    this.getPlatformPermissions();
    this.checkModules();
    this.resetMomentData();
    this.getMomentTemplates();
    this.getGroupFilterTypes();
    this.getGroupFilterConditions();
    this.getGroupFilterTypeValues();     
  } 
}
</script>
